import React from 'react'
import Layout from './components/Layout'

export default function Welcome() {
  return (
    <>
        <Layout>
            <div>
                <h4>Welcome to Schoolley!</h4>
            </div>
        </Layout>
    </>
  )
}
