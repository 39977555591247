
import { createContext, useState } from "react";
import React from "react";

export const AuthenticationContext = createContext({})

export function AuthProvider ({children}) {

    const [authState, setAuthState] = useState({});
    
    return (
        <AuthenticationContext.Provider 
        value={{authState,setAuthState}} > 
            {children}
        </AuthenticationContext.Provider>
    )
}
