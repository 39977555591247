import React,{useState,useEffect,useContext} from 'react';
import { Link} from "react-router-dom";
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import Layout from '../../../components/Layout';
import SkeletonTableRow from '../../../pageComponents/SkeletonTableRow';
import { delayLoading } from '../../../../utils/loadingDelay';

export default function AdministratorArchive() {
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;

  const [isPageLoading,setIsPageLoading] = useState(true);

  const [students,setStudents] = useState([]);
  const [searchValue,setSearchValue] = useState("");
  const [studentsTable,setStudentsTable] = useState([]);

  const [allStaff, setAllStaff] = useState([]);
  const [staffTable, setStaffTable] = useState([]);
  const [overallTable, setOverallTable] = useState([]);

  const [allTabs,setAllTabs] = useState([
    {active:true,tabName:"Students",content:[]},
    {active:false,tabName:"Staff",content:[]},
  ]);

  const handleSelectTab = (index)=>{
    const newTabs = [...allTabs];
    newTabs.forEach((tab)=>{
      tab.active = false;
    });
    newTabs[index].active = true;
    setAllTabs(newTabs);
    if(index === 0){
      setOverallTable(students);
    } else if(index === 1){
      setOverallTable(allStaff);
    }
  }

  const handleSetOverallTable = (data)=>{
    setOverallTable(data);
  }

const handleSearch =(e)=>{
  setSearchValue(e.target.value);
  let filteredList = students.filter((student)=>{
    const searchKeys = ["firstName","lastName","email"];
    return searchKeys.some((key)=>{
      if(typeof student[key] ==="string" && student[key].toLowerCase().includes(e.target.value.toLocaleLowerCase())){
        return student
      }
    })
  });
  setStudentsTable(filteredList);
}

const getArchivedStudents = async()=>{
  await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/administrator/students/archived`,{withCredentials:true})
  .then((response)=>{
      if(response.status === 200){
          setStudents(response.data.payload);
          setOverallTable(response.data.payload);
      }
  })
  .catch((error)=>{
      console.log(error);
  })
  .finally(()=>{
    setIsPageLoading(false);
  })
}

const getArchivedStaff = async ()=>{
  await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/administrator/teachers/archived`,{withCredentials:true})
  .then((response)=>{
    if(response.status === 200){
      setAllStaff(response.data.payload);
    }
  })
  .catch((error)=>{
    console.log(error);
  })
}

useEffect(()=>{
  document.title = "Archived Members";
  // delayLoading(getArchivedStudents,3000);
  getArchivedStudents();
  getArchivedStaff(); 
  // delayLoading(getArchivedStaff,2000);
} 
,[])

  return (
    <Layout>
    <div className='md:w-full w-full'>
      <div className='bg-opacity-75 lg:mx-10'>
        <div className='font-semibold px-2'>
            <p className='text-2xl font-bold mb-2'>Archived School Members</p>
        </div>
        <div className='mt-2 overflow-x-auto shadow-sm rounded-2xl'>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2">
            {allTabs.map((tab,index)=>{
                return (
                  <button 
                    key={index}
                    className={`transition delay-100 flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-3 sm:px-6 lg:border-t-0 xl:px-8 hover:cursor-pointer ${index !==allTabs.length-1 ? "lg:border-r":""} ${tab.active?"bg-indigo-500 text-white":"bg-white"}`}
                    onClick={()=>{handleSelectTab(index)}}>
                      <p className={`w-full font-semibold leading-6 text-gray-500 ${tab.active?"text-white":"text-gray-500"}`}> {tab.tabName}</p>
                      {/* <p className="w-full flex-none text-3xl font-medium leading-10 tracking-tight ">
                      </p> */}
                  </button>
                )})
            }
          </div>
        </div>

        <div className='py-2 flex align-middle'>
          <div className='flex items-center w-full lg:w-3/5 md:w-4/5 sm:w-4/5'>
            <p className='py-2 px-5 font-semibold text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-700'>Search </p>
            <input 
            value={searchValue}
            onChange={handleSearch}
            placeholder='find student' 
            className='bg-white shadow-sm block flex-1 border-0 bg-slate-100 py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-2xl'/>
          </div>
        </div>
            
        <div className="flow-root hover:shadow hover:rounded-2xl">
          <div className="-my-2 overflow-x-auto sm:-mx-0 lg:-mx-0 ">
            <div className="inline-block min-w-full py-2 align-middle sm:px-0 lg:px-0 ">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5 sm:rounded-2xl overflow-auto max-h-160">
                <table className='min-w-full divide-y divide-gray-700 rounded-2xl bg-white' >
                  <thead className='bg-indigo-500 text-white text-sm'>
                    <tr className='text-center'>
                    <th scope="col"  className="py-2 px-3 font-semibold sm:pl-6 w-48">Name</th>
                    <th scope="col" className="px-3 py-2 font-semibold w-48">Email</th>
                    <th scope="col" className="px-3 py-2 font-semibold w-44">School</th>

                    <th scope="col" className="px-3 py-2 font-semibold w-44">Grade</th>
                    <th scope="col" className="px-3 py-2 font-semibold w-44">Activated</th>
                    <th scope="col" className="px-3 py-2 font-semibold w-44">Archived</th>
                    </tr>
                  </thead>

                  <tbody className='divide-y divide-gray-300'>

                    { 
                      isPageLoading?
                      <SkeletonTableRow colNumber={6} rowNumber={10} />
                      :   // after page loading
                      overallTable.map((member)=>{
                        return (
                        <tr key={member.id} className='hover:bg-gray-100 text-center bg-white' >
                          <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm text-blue-600 font-semibold'>
                          {member.role === "student"?
                            <Link to={`/school/administrator/archive/student/${member.id}`} >
                              {member.firstName} {member.lastName} </Link> : 
                            <Link to={`/school/administrator/archive/staff/${member.id}`} >{member.firstName} {member.lastName}</Link>
                          }
                          </td>
                          <td className='whitespace-nowrap px-3 text-sm'>{member.email}</td>
                          <td className='text-sm whitespace-nowrap px-3'>
                          {member.school?.schoolName}
                          </td>
                          <td className='text-sm whitespace-nowrap px-3'>
                          {member.gradeLevel}
                          </td>
                          <td className='text-sm whitespace-nowrap px-3'>{member.activated?"Active":"Inactive"}</td>
                          <td className='text-sm whitespace-nowrap px-3'>{member.archived?"YES":"NO"}</td>
                        </tr>
                        )})
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  )
}
