import { axiosWithInterceptor } from "../api/axios";
import { useEffect } from "react";
import useAuthenticationContext from "./authStateHook";
import useRefreshToken from "./useRefreshToken";

const useAxiosWithInterceptor =  ()=>{
    const {authState} = useAuthenticationContext();
    const doRefreshToken = useRefreshToken();
    
    useEffect(()=>{

        const requestIntercept = axiosWithInterceptor.interceptors.request.use(
            (config) =>{
                // console.log("AuthState in Request Interceptor:", authState);

                // if(!config.headers['Authorization']){
                //     config.headers['Authorization'] = `Bearer ${authState.accessToken}`          // old logic before testing debugging with chatgpt
                // }
                if (authState.accessToken) {
                    config.headers['Authorization'] = `Bearer ${authState.accessToken}`;
                } else {
                    console.warn("No access token available for this request.");
                }
                return config;
            },
            (error)=>{
                if (process.env.NODE_ENV === 'development'){
                    console.log("Request Interceptor Error:", error.message);
                } else {
                    console.log("An error occurred in the request interceptor.");
                }
                return Promise.reject(error);
                // chatgpt told me to add 'return' before Promise.reject(error)
            }
        );

        // Response interceptor to handle token refresh logic
        const responseIntercept = axiosWithInterceptor.interceptors.response.use(
            response =>response, 
            async (error)=>{
                const failedRequest = error?.config;

                console.log("Error Config:", failedRequest);
                console.log("Error Response:", error.response?.data);

                if(error?.response?.status === 401 && !failedRequest?.sent){
                    failedRequest.sent = true;

                    try{
                        console.log("Attempting to refresh token...");
                        const newAccessToken = await doRefreshToken();
                    // Update the request with the new token
                        failedRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        return axiosWithInterceptor(failedRequest);

                    }catch(refreshError){
                        // Propagate the refresh error to the calling function
                        console.error("Refresh Token Error:", refreshError);
                        
                        return Promise.reject(refreshError);
                        // Forward other errors
                    }
                }
                console.error("Unhandled Response Interceptor Error:", error);

                // if (process.env.NODE_ENV === 'development') {
                //     console.log("Response Interceptor Error:", error.message);
                // } else {
                //     console.log("An error occurred in the response interceptor.");
                // }
                
                return Promise.reject(error)
            }
        )

    //cleanup function react will execute this so to clear the interceptor
        return ()=>{
            axiosWithInterceptor.interceptors.request.eject(requestIntercept);
            axiosWithInterceptor.interceptors.response.eject(responseIntercept);
        }

    },[authState, doRefreshToken])

// by the time this returns axiosWithInterceptor, tokens will be attached
    return axiosWithInterceptor
}

export default useAxiosWithInterceptor;