import React, { useEffect ,useContext} from 'react'
import { Fragment, useState } from 'react'
import {Bars3Icon} from '@heroicons/react/24/outline'

import { AuthenticationContext } from '../../services/AuthContextModule'

import SchoolStaffSidebar from './Sidebar/SchoolStaffSidebar';
import SuperAdminSidebar from './Sidebar/SuperAdminSidebar';
import StudentSidebar from './Sidebar/StudentSidebar';
import NotificationWidget from './Notification'
import ProfileWidget from './ProfileWidget';
import ParentSidebar from './Sidebar/ParentSidebar';

export default function Layout({children}) {

  const {authState} = useContext(AuthenticationContext);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  var sidebar;

  if(typeof authState.role === "string"){
    if(authState.role ==="superAdmin"){
      sidebar = <SuperAdminSidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
    }else if(authState.role === "student"){
      sidebar = <StudentSidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}  />
    } else if (authState.role === "parent"){
      sidebar = <ParentSidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
    }
  }

  if(Array.isArray(authState.role)){
    if(authState.role.includes("counselor") || authState.role.includes("administrator") || authState.role.includes("teacher")){ 
      sidebar = <SchoolStaffSidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
    }
  }

  return (
    <>
      <div className='h-full min-h-screen flex flex-col bg-slate-100'>
        {/* sidebar */}
        {sidebar}
        {/* top navbar */}
        <div className="lg:pl-56 my-0 ">
          <div className="sticky top-0 z-40 flex h-12 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            {/* Separator */}
            
            <div className="h-6 w-px bg-gray-900/10 lg:hidden " aria-hidden="true" />
              <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 ">
                <div className="relative flex flex-1" >
  
                </div>
                <div className="relative flex items-center gap-x-4 lg:gap-x-6 ">
                  <div className='font-semibold text-sm px-3 py-0.5 rounded-2xl capitalize shadow-sm border-2 border-white bg-slate-100 text-blue-600'>{displayUserRole(authState.role)}</div>
                  <NotificationWidget />
                  <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />
                  <ProfileWidget />
                </div>
              </div>
          </div>

          <main className="px-2 sm:px-6 lg:px-8 py-3 flex-1 overflow-y-auto font-lato">
            {children}
          </main>
          
          <footer className="fixed bottom-0 w-full z-0 border-t border-gray-900/10">
              <p className="mx-5 text-xs leading-5 text-gray-500">&copy; 2024 Schoolley, Inc. All rights reserved.</p>
          </footer>
        </div>
      </div>
    </>
  )
}

const displayUserRole = (roles)=>{

  if(roles.includes("teacher")||roles.includes("counselor")||roles.includes("administrator"||roles.include("schoolAdmin"))){

    const roleOrder = ['schoolAdmin', 'teacher', 'counselor','administrator'];

    const sortedRoles = roles.filter(role => roleOrder.includes(role)).sort((a, b) => roleOrder.indexOf(a) - roleOrder.indexOf(b)); 
  
    return <>{sortedRoles.join(' / ')}</>;

  }  else {
    return (
      <>
        {roles}
      </>
    )
  }
}